var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-template',[_c('LoadingPage',{directives:[{name:"show",rawName:"v-show",value:(_vm.loaded),expression:"loaded"}]}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loaded),expression:"!loaded"}]},[_c('NbPageTitle',{staticClass:"mb-4",attrs:{"title":_vm.$t('downloadsPage.title'),"subTitle":_vm.$t('downloadsPage.subtitle')}}),_c('NbTablev2',{ref:"downloadsPage",staticClass:"mt-5",attrs:{"tableOf":"downloadsPage","hasParentItens":true,"hiddeTableOptions":true,"height":"fit-content","allFields":_vm.allFields,"fields":_vm.fields,"startItems":_vm.downloads},on:{"reloadFields":function($event){_vm.fields = $event}},scopedSlots:_vm._u([{key:"cell(file_type)",fn:function(data){return [(data.item.data_type == 'order_import')?_c('span',[_vm._v("Upload")]):_c('span',[_vm._v("Download")])]}},{key:"cell(status)",fn:function(data){return [_c('div',[(
              data.item.df_errors &&
              data.item.df_errors[0] == 'import initiated' &&
              data.item.data_type == 'order_import'
            )?_c('NbBadge',{staticClass:"fit-content",attrs:{"type":"warning","text":"import initiated"}}):_c('NbBadge',{staticClass:"fit-content",attrs:{"type":_vm.badgeType(data.item.status),"text":_vm.badgeText(data.item.status)}})],1)]}},{key:"cell(file_name)",fn:function(data){return [_c('span',[_vm._v(_vm._s(data.item.name ? data.item.name : "-"))])]}},{key:"cell(errors)",fn:function(data){return [(
            data.item.df_errors &&
            data.item.df_errors[0] == 'import completed' &&
            data.item.data_type == 'order_import'
          )?_c('NbBadge',{staticClass:"fit-content",attrs:{"type":"success","text":"import completed"}}):(
            data.item.df_errors &&
            data.item.df_errors[0] == 'import initiated'
          )?_c('div',{staticClass:"link-1",attrs:{"variant":"warning","title":"Import initiated"}},[_vm._v(" Import Initiated ")]):(data.item.df_errors && data.item.df_errors.length > 0)?_c('div',{staticClass:"link-1",attrs:{"variant":"danger","title":"Show Errors","data-toggle":"modal","data-target":"#showErrors"},on:{"click":function($event){_vm.currentErrors = data.item.df_errors}}},[_vm._v(" Show Errors ")]):(
            data.item.status == 'done' &&
            data.item.data_type == 'order_import'
          )?_c('div',{staticClass:"link-1",attrs:{"variant":"success","title":"import_response page"},on:{"click":function($event){return _vm.importPage(data.item.id)}}},[_vm._v(" Resume importation ")]):_vm._e()]}},{key:"cell(actions)",fn:function(data){return [(data.item.status == 'done')?_c('a',{staticClass:"link-1",attrs:{"href":"#"},on:{"click":function($event){return _vm.downloadFiles(data.item.id)}}},[_c('i',{staticClass:"fas fa-download"}),_vm._v(" "+_vm._s(_vm.$t("download"))+" ")]):_vm._e()]}}])}),_c('NbFooter',{attrs:{"text":_vm.$t('components.footer.checkOurSupport'),"link":"https://nobordistinc.freshdesk.com/support/home"}})],1),_c('NbModal',{attrs:{"id":"showErrors","modalConfig":"modal-dialog-centered","width":"46rem"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"d-flex mb-4"},[_c('h5',{staticClass:"heading-4 ml-3 mb-1"},[_vm._v(_vm._s(_vm.$t("downloadsPage.errors")))])])]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-end"},[_c('NbButton',{attrs:{"variant":"secondary","data-dismiss":"modal"}},[_vm._v(" "+_vm._s(_vm.$t("close"))+" ")])],1)]},proxy:true}])},[_c('div',_vm._l((_vm.currentErrors),function(currentError){return _c('div',{key:currentError,staticClass:"border-bottom pb-2 mb-2"},[_vm._v(" - "+_vm._s(currentError)+" ")])}),0)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }