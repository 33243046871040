<template>
  <base-template>
    <LoadingPage v-show="loaded" />
    <div v-show="!loaded">
      <NbPageTitle
        :title="$t('downloadsPage.title')"
        :subTitle="$t('downloadsPage.subtitle')"
        class="mb-4"
      />

      <NbTablev2
        class="mt-5"
        tableOf="downloadsPage"
        :hasParentItens="true"
        ref="downloadsPage"
        :hiddeTableOptions="true"
        height="fit-content"
        :allFields="allFields"
        :fields="fields"
        @reloadFields="fields = $event"
        :startItems="downloads"
      >
        <template v-slot:cell(file_type)="data">
          <span v-if="data.item.data_type == 'order_import'">Upload</span>
          <span v-else>Download</span>
        </template>

        <template #cell(status)="data">
          <div>
            <NbBadge
              v-if="
                data.item.df_errors &&
                data.item.df_errors[0] == 'import initiated' &&
                data.item.data_type == 'order_import'
              "
              class="fit-content"
              type="warning"
              text="import initiated"
            />
            <NbBadge
              v-else
              class="fit-content"
              :type="badgeType(data.item.status)"
              :text="badgeText(data.item.status)"
            />
          </div>
        </template>

        <template v-slot:cell(file_name)="data">
          <span>{{ data.item.name ? data.item.name : "-" }}</span>
        </template>

        <template v-slot:cell(errors)="data">
          <NbBadge
            v-if="
              data.item.df_errors &&
              data.item.df_errors[0] == 'import completed' &&
              data.item.data_type == 'order_import'
            "
            class="fit-content"
            type="success"
            text="import completed"
          />
          <div
            v-else-if="
              data.item.df_errors &&
              data.item.df_errors[0] == 'import initiated'
            "
            variant="warning"
            class="link-1"
            title="Import initiated"
          >
            Import Initiated
          </div>
          <div
            v-else-if="data.item.df_errors && data.item.df_errors.length > 0"
            variant="danger"
            class="link-1"
            title="Show Errors"
            data-toggle="modal"
            data-target="#showErrors"
            @click="currentErrors = data.item.df_errors"
          >
            Show Errors
          </div>
          <div
            v-else-if="
              data.item.status == 'done' &&
              data.item.data_type == 'order_import'
            "
            variant="success"
            class="link-1"
            title="import_response page"
            @click="importPage(data.item.id)"
          >
            Resume importation
          </div>
        </template>
        <template v-slot:cell(actions)="data">
          <a
            v-if="data.item.status == 'done'"
            href="#"
            class="link-1"
            @click="downloadFiles(data.item.id)"
          >
            <i class="fas fa-download"></i> {{ $t("download") }}
          </a>
        </template>
      </NbTablev2>

      <NbFooter
        :text="$t('components.footer.checkOurSupport')"
        link="https://nobordistinc.freshdesk.com/support/home"
      />
    </div>
    <!-- modals -->
    <NbModal id="showErrors" modalConfig="modal-dialog-centered" width="46rem">
      <template v-slot:header>
        <div class="d-flex mb-4">
          <h5 class="heading-4 ml-3 mb-1">{{ $t("downloadsPage.errors") }}</h5>
        </div>
      </template>
      <div>
        <div
          v-for="currentError in currentErrors"
          :key="currentError"
          class="border-bottom pb-2 mb-2"
        >
          - {{ currentError }}
        </div>
      </div>
      <template v-slot:footer>
        <div class="d-flex justify-content-end">
          <NbButton variant="secondary" data-dismiss="modal">
            {{ $t("close") }}
          </NbButton>
        </div>
      </template>
    </NbModal>
    <!-- end modals -->
  </base-template>
</template>

<script>
import BaseTemplate from "./BaseTemplate";
import LoadingPage from "@/components/loadings/LoadingPage.vue";
import NbPageTitle from "@/components/pagescomponents/NbPageTitle";
import NbTablev2 from "@/components/tables/NbTablev2";
import NbBadge from "@/components/alerts/NbBadge.vue";
import NbFooter from "@/components/pagescomponents/NbFooter.vue";
import NbModal from "@/components/modal/NbModal.vue";
import NbButton from "@/components/buttons/NbButton.vue";

import DownloadsService from "@/services/DownloadsService";

const downloadsService = new DownloadsService();

export default {
  name: "Downloads",
  components: {
    BaseTemplate,
    LoadingPage,
    NbPageTitle,
    NbTablev2,
    NbBadge,
    NbFooter,
    NbModal,
    NbButton,
  },
  data: () => {
    return {
      downloads: [],
      currentErrors: null,
      fields: [],
      namespace: "downloaded_files",
      loaded: false,
      /*  autoReload: 0,
      reloadCount: 0,
      reloadLimit: 11, */
      setIntervalControl: null,
    };
  },
  created() {
    this.fields = this.allFields;
    this.getData();
    this.loadItems();
  },
  beforeDestroy() {
    clearInterval(this.setIntervalControl);
  },

  methods: {
    badgeType(status) {
      if (status == "failed") {
        return "danger";
      }

      if (
        status == "processing" ||
        status == "starting_upload" ||
        status == "checking_csv_format" ||
        status == "checking_columns" ||
        status == "checking_equal_orders_volumes" ||
        status == "checking_cells" ||
        status == "validation_success"
      ) {
        return "warning";
      }

      return "success";
    },
    badgeText(status) {
      if (status == "done") {
        return this.$t("downloadsPage.status.done");
      }
      if (status == "failed") {
        return this.$t("downloadsPage.status.failed");
      }

      if (status == "processing") {
        return this.$t("downloadsPage.status.processing");
      }
      if (status == "starting_upload") {
        return this.$t("downloadsPage.status.starting_upload");
      }

      if (status == "checking_csv_format") {
        return this.$t("downloadsPage.status.checking_csv_format");
      }
      if (status == "checking_columns") {
        return this.$t("downloadsPage.status.checking_columns");
      }
      if (status == "checking_equal_orders_volumes") {
        return this.$t("downloadsPage.status.checking_equal_orders_volumes");
      }
      if (status == "checking_cells") {
        return this.$t("downloadsPage.status.checking_cells");
      }
      if (status == "validation_success") {
        return this.$t("downloadsPage.status.validation_success");
      }
      return "Finished";
    },
    loadItems() {
      this.setIntervalControl = setInterval(() => {
        this.getData();
      }, 8000);
      /*
      setInterval(() => {
        if (this.autoReload > 0) {
          this.autoReload--;
          return;
        }
        if (this.autoReload === 0 && this.reloadCount < this.reloadLimit) {
          this.getData();
          this.reloadCount++;
          if (this.reloadCount < 3) {
            this.autoReload = this.reloadCount * 3;
            return;
          }
          this.autoReload = 8;
        }
      }, 1000);
      */
    },
    getData() {
      //this.loaded = true;
      downloadsService.getDownloads().then((response) => {
        this.downloads = response.data.data;
        this.loaded = false;
        this.downloads.forEach((element, index) => {
          let time = element.created_at;
          let date = element.created_at;
          // this.downloads[index].time = this.formateTime(time);
          this.downloads[index].created_at = this.formateDate(date, time);
        });
      });
    },
    importPage(Id) {
      this.$router.push({
        path: "/orders/import_response",
        query: { orders: Id },
      });
    },
    reloadFields(event) {
      this.fields = event;
    },
    addZero(number) {
      if (number <= 9) {
        return "0" + number;
      } else {
        return number;
      }
    },
    formateDate(date, time) {
      const data = new Date(date);
      const hours = new Date(time);
      const formatedDate =
        this.addZero(
          data.getDate().toString() +
            "/" +
            this.addZero(data.getMonth() + 1).toString() +
            "/" +
            data.getFullYear()
        ) +
        "  " +
        this.addZero(hours.getHours()) +
        ":" +
        this.addZero(hours.getMinutes()) +
        ":" +
        this.addZero(hours.getSeconds());
      return formatedDate;
    },
    downloadFiles(fileID) {
      downloadsService.generateLink(fileID).then((response) => {
        let csv = response.data.data.link;
        let filename = response.data.data.file_name;
        let anchor = document.createElement("a");
        anchor.href = csv;
        anchor.target = "_blank";
        anchor.download = filename;
        anchor.click();
      });
    },
  },
  computed: {
    allFields() {
      return [
        //i18n.doc utilizado em javascript
        {
          key: "id",
          label: this.$t("downloadsPage.fields.id"),
          sortable: false,
        },
        {
          key: "data_type",
          label: this.$t("downloadsPage.fields.type"),
          sortable: false,
        },
        { key: "file_type", label: "File Type", sortable: false },
        {
          key: "status",
          label: this.$t("downloadsPage.fields.status"),
          sortable: false,
        },
        {
          key: "file_name",
          label: this.$t("downloadsPage.fields.fileName"),
          sortable: false,
        },
        {
          key: "errors",
          label: this.$t("downloadsPage.fields.messages"),
          sortable: false,
          thClass: ["medium-content"],
        },
        {
          key: "created_at",
          label: this.$t("downloadsPage.fields.creationDate"),
          sortable: false,
        },
        {
          key: "actions",
          isCustomizable: false,
          label: this.$t("actions"),
          class: "right--20 right-sticky",
        },
      ];
    },
  },
};
</script>
